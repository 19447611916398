<template>
    <v-navigation-drawer v-model="sidebar" style="background-image: linear-gradient(to bottom, #f4511e, #f87037, #fb8a51, #fda26e);" app>
        <v-list class="text-white">
            <template v-for="item in this.categories" :key="item.name">
                <v-list-group v-if="item.subcategories.length > 0" :value="item.name">
                    <template v-slot:activator="{ props }">
                        <v-list-item v-bind="props" style="font-family:alkatip;" :title="item.name"></v-list-item>
                    </template>
                    <v-list-item v-for="item in item.subcategories" :key="item.name" color="black" :to="item.path">
                        <v-list-item-title style="font-family:alkatip;" v-text="item.name"></v-list-item-title>
                    </v-list-item>
                </v-list-group>
                <v-list-item v-else color="black" :to="item.path">
                    <v-list-item-title style="font-family:alkatip;" v-text="item.name"></v-list-item-title>
                </v-list-item>
            </template>
        </v-list>
    </v-navigation-drawer>
    <v-app-bar flat height="96" class="bg-transparent" app>
        <v-sheet flat width="1200" class="mx-auto">
            <v-toolbar style="background-image: linear-gradient(to bottom, #f4511e, #f87037, #fb8a51, #fda26e);" class="text-white" height="96">
                <span class="hidden-sm-and-up">
                    <v-btn icon @click="sidebar = !sidebar"><v-icon>mdi-menu</v-icon></v-btn>
                </span>
                <v-avatar class="mr-sm-2" size="82">
                    <v-img class="bg-white" contain src="@/assets/ayhan512.png"></v-img>
                </v-avatar>
                <v-card flat class="uyghur text-h6 mr-2 bg-transparent text-center" min-width="220">ئايخان مەدەنىيەت ۋە مائارىپ فوندى</v-card>
                <v-card flat width="50"></v-card>
                <v-slide-group md-show-arrows>
                    <v-slide-group-item v-for="(item, i) in categories" :key="i" v-slot:default="{ active, toggle }">
                        <v-btn v-if="item.subcategories.length == 0" class="text-h6 uyghur px-3 mx-1 my-1 pb-2" rounded
                            :variant="active ? 'rounded' : 'text'" @click="toggle" :to="item.path">{{ item.name }}</v-btn>
                        <v-menu v-else open-on-hover>
                            <template v-slot:activator="{ props }">
                                <v-btn v-bind="props" class="text-h6 uyghur px-3 mx-1 my-1 pb-2" rounded
                                    :variant="active ? 'rounded' : 'text'">
                                    {{ item.name }}
                                </v-btn>
                            </template>

                            <v-list class="bg-deep-orange-lighten-1 text-white">
                                <v-list-item v-for="(item, index) in item.subcategories" :key="index" :to="item.path">
                                    <v-list-item-title style="font-family:alkatip;">{{ item.name }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-group-item>
                </v-slide-group>
                <v-spacer></v-spacer>
            </v-toolbar>
        </v-sheet>
    </v-app-bar>
</template>
<script>
export default {
    data: () => ({
        sidebar: false,
        categories: [],
    }),
    created() {
        fetch('https://anatil.xsada.net/api/categories', {
            method: 'GET',
        }).then(resp => resp.json())
            .then(resp => this.categories = resp)
            .catch(error => console.log(error))
    },
    methods: {
        
    },

    computed: {
        filteredCategory() {
            if (!this.$store.state.accessToken) {
                return this.categories.filter(
                    item => item.is_inner == false
                )
            } else {
                return this.categories
            }
        }
    }
}
</script>